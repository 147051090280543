import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import mixpanel from "mixpanel-browser"

const isProduction = () => {
    const hostname = window.location.hostname
    return !["localhost", "127.0.0.1", ""].includes(hostname)
}

mixpanel.init("a734c0628972a15ee5fafaa2221d8430")

if (!isProduction()) {
    mixpanel.disable() // Disables all Mixpanel tracking if not in production
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
