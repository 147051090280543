import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
    TextField,
    Button,
    Typography,
    Container,
    Box,
    useTheme,
    CircularProgress,
    useMediaQuery,
    Fade,
} from "@mui/material"
import Grid from "@mui/material/Grid2"
import SearchIcon from "@mui/icons-material/Search"
import HomeViewModel from "./HomeViewModel"
import mixpanel from "mixpanel-browser"
import {AnalyticsEvent} from "../../services/Analytics"
import {triggerGTMEvent} from "../../services/GTM"

const Home = observer(() => {
    const theme = useTheme()
    const [viewModel] = useState(() => new HomeViewModel())
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

    const handleSearch = async () => {
        await viewModel.search()
    }

    const onResultsPress = () => {
        if (viewModel.response?.searchURL) {
            window.open(viewModel.response.searchURL, "_blank")
        }
    }

    return (
        <Container
            maxWidth="md"
            style={{ backgroundColor: theme.palette.background.default, minHeight: "100vh" }}
        >
            <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
                <Typography variant="h2" style={{ marginTop: 48, marginBottom: 48 }} fontWeight={300}>
                    cruiseSearch
                    <Typography variant="h2" component="span" style={{ color: theme.palette.secondary.main }} fontWeight={700}>
                        .ai
                    </Typography>
                </Typography>

                {/* Grid for search box */}
                <Grid container spacing={2} style={{ marginBottom: 2, width: "98%" }}>
                    <Grid size={{ xs: 12, sm: 10, md: 10 }}>
                        <TextField
                            disabled={viewModel.isLoading}
                            variant="outlined"
                            placeholder="Type a search phrase in natural language..."
                            fullWidth
                            value={viewModel.searchTerm}
                            onChange={(e) => viewModel.setSearchTerm(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch()
                                }
                            }}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 2, md: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                            disabled={viewModel.isLoading}
                            fullWidth
                            style={{ height: "100%" }}
                        >
                            {viewModel.isLoading ? <CircularProgress size={24} /> : <SearchIcon />}
                        </Button>
                    </Grid>
                </Grid>

                {viewModel.response && !viewModel.isLoading && (
                    <Fade in={!!viewModel.response && !viewModel.isLoading} timeout={500}>
                        <Box display="flex" justifyContent="center" style={{ marginTop: theme.spacing(6) }}>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                style={{
                                    padding: theme.spacing(4),
                                    backgroundColor: theme.palette.mode === "dark"
                                        ? theme.palette.grey[800]
                                        : theme.palette.grey[200],
                                    borderRadius: theme.shape.borderRadius,
                                }}
                            >
                                <Grid size={{ xs: 12, md: "auto" }}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            marginRight: isMdUp ? theme.spacing(4) : 0,
                                            marginBottom: !isMdUp ? theme.spacing(2) : 0,
                                            textAlign: !isMdUp ? "center" : "left",
                                            flex: 1,
                                        }}
                                    >
                                        CruiseDirect.com: Found {viewModel.response.resultCount}{" "}
                                        {viewModel.response.resultCount === 1 ? "cruise" : "cruises"}
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12, md: "auto" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        href={viewModel.response.searchURL}
                                        onClick={() => {
                                            triggerGTMEvent()
                                            mixpanel.track(AnalyticsEvent.RESULTS_CLICK)
                                        }}
                                        target="_blank">
                                        View Cruises
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                )}

                {/* Fade for help section */}
                {!viewModel.response && !viewModel.isLoading && (
                    <Fade in={!viewModel.response && !viewModel.isLoading} timeout={500}>
                        <Grid container justifyContent="center">
                            <Grid size={{ xs: 12, sm: 12 }}>
                                <Typography
                                    variant="body1"
                                    style={{ marginTop: 48, color: theme.palette.grey[500], textAlign: "left" }}
                                >
                                    You can search for cruises using natural language like:
                                    <br />
                                    <br />
                                    &middot; "7-day Caribbean cruise in August"
                                    <br />
                                    &middot; "Cheap cruise out of Florida ports"
                                    <br />
                                    &middot; "Mediterranean cruises on Royal next summer"
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
            </Box>
        </Container>
    )
})

export default Home
