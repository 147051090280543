import { makeAutoObservable } from "mobx"
import AquataApi, {SearchResponse} from "../../services/AquataApi"
import mixpanel from "mixpanel-browser"
import {AnalyticsEvent} from "../../services/Analytics"

class HomeViewModel {
    api = new AquataApi()
    searchTerm = ""
    response?: SearchResponse
    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    setSearchTerm(term: string) {
        this.searchTerm = term
    }

    setResponse(r: SearchResponse | undefined) {
        this.response = r
    }

    setIsLoading(loading: boolean) {
        this.isLoading = loading
    }

    async search() {
        mixpanel.track(AnalyticsEvent.SEARCH, {
            term: this.searchTerm
        })

        try {
            this.setIsLoading(true)
            const response = await this.api.search(this.searchTerm)
            this.setResponse(response)

            if (response) {
                mixpanel.track(AnalyticsEvent.SEARCH_RESULTS, {
                    term: this.searchTerm,
                    num_results: response.resultCount
                })
            }
        } catch (e) {
            console.log(e)
            // You might want to handle the error more gracefully here
        } finally {
            this.setIsLoading(false)
        }
    }
}

export default HomeViewModel
