import {ThemeProvider, createTheme} from "@mui/material/styles"
import {CssBaseline, responsiveFontSizes} from "@mui/material"
import Home from "./pages/home/Home"

// App colors
enum Colors {
    tint = "#9600AD",
    secondaryTint = "#960300",
    tertiaryTint = "#7A0075",
    quaternaryTint = "#E0025F"
}

let theme = createTheme({
    typography: {
        fontFamily: "\"Lato\", sans-serif",
        body1: {
            fontWeight: 500
        }
    },
    colorSchemes: {
        dark: {
            palette: {
                mode: "dark",
                primary: {
                    main: Colors.tint,
                },
                secondary: {
                    main: Colors.secondaryTint,
                },
            },
        },
        light: {
            palette: {
                mode: "light",
                primary: {
                    main: Colors.tint,
                },
                secondary: {
                    main: Colors.secondaryTint,
                },
            },
        },
    },
})

theme = responsiveFontSizes(theme)

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Home/>
        </ThemeProvider>
    )
}

export default App
