// Ensure dataLayer exists on the window object
declare global {
    interface Window {
        dataLayer: any[]
    }
}

export function triggerGTMEvent() {
    // Push the custom event to the GTM dataLayer
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: "click_cruise_search" // This should match the event name in GTM
    })
}
