import axios, {AxiosInstance, AxiosResponse} from "axios"

const isDev = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
const BASE_URL = isDev ? "http://127.0.0.1:5002/aquata-7184/us-central1/api"
    : "https://cruisesearch.ai/api"

export interface SearchResponse {
    searchURL: string
    resultCount: number
}

class AquataApi {
    private api: AxiosInstance

    constructor(baseURL: string = BASE_URL) {
        this.api = axios.create({
            baseURL,
            headers: {
                "Content-Type": "application/json",
            },
        })
    }

    async search(prompt: string): Promise<SearchResponse|undefined> {
        try {
            const response: AxiosResponse<SearchResponse> = await this.api.post("/search", {
                prompt,
            })
            return response.data
        } catch (error: any) {
            return undefined
        }
    }
}

export default AquataApi
